import React from 'react'
import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'
import { Link } from 'gatsby'
import styled from 'styled-components'
import PageButtons from '../components/page-buttons'
import { setLightboxForm } from '../actions'
import Copy from '../components/page-copy'
import Row from '../components/page-content-row'
import Sep from '../components/page-sep'
import Image from '../components/page-image-new'
import Overview from '../components/page-overview'
import PageOverview from '../components/page-overview'

import { theme } from '../util/style'
import { connect } from 'react-redux'

import Image2 from '../images/etc/happyhaus-photo-2020-03-10-photo-st-lucia-haus-2-am-1.jpg'
import Image3 from '../images/etc/happyhaus-photo-2020-03-20-photo-st-lucia-haus-2-2041.jpg'
import Image4 from '../images/etc/happyhaus-photo-2019-08-07-photo-fuji-pope-2006.jpg'
const ogImages = [
  `https://happyhaus.com.au${Image2}`,
  `https://happyhaus.com.au${Image3}`,
  `https://happyhaus.com.au${Image4}`,
]

// const Land1 = 'etc/happyhaus-photo-2020-03-10-photo-st-lucia-haus-2-am-1.jpg'
const Land2 = 'etc/happyhaus-photo-2020-03-20-photo-st-lucia-haus-2-2041.jpg'
const Land3 = 'etc/happyhaus-photo-2019-08-07-photo-fuji-pope-2006.jpg'

import AnimGif from '../images/etc/happyhaus-animation-together.gif'
import AnimGifMobile from '../images/etc/happyhaus-animation-together-mobile.gif'

const mapDispatchToProps = dispatch => ({})

const Page = ({ dispatch }) => {
  const click = (e, dispatch, title, redirect) => {
    e.preventDefault()
    dispatch(
      setLightboxForm({
        category: '',
        title: title,
        redirect: redirect,
      })
    )
  }

  return (
    <>
      <SEO
        title="We’ll get through this together."
        description="We’ll get through this together. We’ll just be slightly further apart. Stay inspired and keep the dream alive."
        images={ogImages}
      />
      <Row>
        <Flex flexWrap="wrap" flexDirection={['column', 'row']}>
          <Box width={[1, 6 / 12]} px={2}>
            <PageOverview>
              <p>While we're slightly further apart.</p>
            </PageOverview>
          </Box>
          <Box width={[1, 6 / 12]} px={2}>
            <Copy>
              <p>
                Here’s how we're currently responding to COVID-19 and some ideas
                for you to consider if you’re wondering how you can still start
                planning your new home project.
              </p>
            </Copy>
          </Box>
        </Flex>
      </Row>

      <Row>
        <Flex>
          <Box width={1} px={2}>
            <Desktop>
              <img src={AnimGif} alt="together" />
            </Desktop>
            <Mobile>
              <img src={AnimGifMobile} alt="together" />
            </Mobile>
          </Box>
        </Flex>
      </Row>

      <Row>
        <Flex flexWrap={['wrap', 'nowrap']}>
          <Box width={[1, 6 / 12]} px={2} pr={[0, 0]}>
            <Overview>
              <p>Book a 1:1 design consult</p>
            </Overview>
          </Box>
          <Box width={[1, 6 / 12]} px={2} mr={[0, 0]}>
            <Copy align="left">
              <p>
                We’re offering video call consults with our design team. We’d
                love to walk you through how we make a Happy Haus, in person or
                remotely from the comfort of your home or office.
              </p>
              <PageButtons
                align="left"
                inCopy={true}
                data={[
                  {
                    label: 'Book a consult',
                    onClick: e => {
                      return click(
                        e,
                        dispatch,
                        'Ready to discuss next steps?',
                        '/contact-thank-you'
                      )
                    },
                    background: theme.colors.grey1,
                  },
                ]}
              />
            </Copy>{' '}
            <Copy size="sm">
              <p>
                If you are more comfortable, we're setup to do all our
                consultations via video call.
              </p>
            </Copy>
          </Box>
        </Flex>
      </Row>

      <Sep />

      <Row>
        <Flex flexWrap={['wrap', 'nowrap']}>
          <Box width={[1, 6 / 12]} px={2} pr={[0, 0]}>
            <Overview>
              <p>Visit Display Haus safely</p>
            </Overview>
          </Box>
          <Box width={[1, 6 / 12]} px={2} mr={[0, 0]}>
            <Copy align="left">
              <p>
                Display Haus walk-throughs are available on Saturdays. We open
                up all our beautiful windows and courtyards and let you through
                safely while observing social distancing requirements.
              </p>
              <PageButtons
                align="left"
                inCopy={true}
                data={[
                  {
                    label: 'Plan your visit',
                    onClick: e => {
                      return click(
                        e,
                        dispatch,
                        'Ready to discuss next steps?',
                        '/contact-thank-you'
                      )
                    },
                    background: theme.colors.grey1,
                  },
                ]}
              />
            </Copy>
            <Copy size="sm">
              <p>
                Inspections are contactless and we provide sanitiser so you can
                still interact safely with all the features in Display Haus. As
                per government regulations we can only allow a maximum of 10
                people through at a time and all visitors are required to
                register open entry.
              </p>
            </Copy>
          </Box>
        </Flex>
      </Row>

      <Sep />

      <Row>
        <Flex flexWrap={['wrap', 'nowrap']}>
          <Box width={[1, 6 / 12]} px={2} pr={[0, 0]}>
            <Overview>
              <p>You can still start planning</p>
            </Overview>
          </Box>
          <Box width={[1, 6 / 12]} px={2} mr={[0, 0]}>
            <Copy align="left">
              <p>
                Did you know it can take up to 6 months of planning and design
                before commencing construction of your new home build? Instead
                of “one day”, today could be “day one!”
              </p>
              <PageButtons
                align="left"
                inCopy={true}
                data={[
                  {
                    label: 'Start the conversation',
                    onClick: e => {
                      return click(
                        e,
                        dispatch,
                        'Ready to discuss next steps?',
                        '/contact-thank-you'
                      )
                    },
                    background: theme.colors.grey1,
                  },
                ]}
              />
            </Copy>
            <Copy size="sm">
              <p>
                All our design work continues as normal and if you are more
                comfortable we're setup to do all our client workshops via video
                call consultations.
              </p>
            </Copy>
          </Box>
        </Flex>
      </Row>

      <Sep />

      <Row>
        <Flex flexWrap={['wrap', 'nowrap']}>
          <Box width={[1, 6 / 12]} px={2} pr={[0, 0]}>
            <Overview>
              <p>Learn about your options</p>
            </Overview>
          </Box>
          <Box width={[1, 6 / 12]} px={2} mr={[0, 0]}>
            <Copy align="left">
              <p>
                Stuck at home? Crochet not your thing? Why not learn more about
                building your dream home with our free guides and checklists to
                help on your journey?
              </p>
              <PageButtons
                align="left"
                inCopy={true}
                data={[
                  {
                    label: 'View our resources',
                    link: '/resources',
                    background: theme.colors.grey1,
                  },
                ]}
              />
            </Copy>
            <Copy size="sm">
              <p>
                Whether you're considering a knockdown rebuild, have land ready
                to go, or you’re just learning, we have handy free guides for
                you.
              </p>
            </Copy>
          </Box>
        </Flex>
      </Row>

      <Row>
        <Flex flexWrap={['wrap', 'nowrap']}>
          <Box width={[1, 6 / 12]} px={2}>
            <Image src={Land3} ratio={1 / 1.5} />
            <br />
            <Overview>
              Haus <em>still</em> in progress
            </Overview>
            <br />
            <Copy align="left">
              <p>
                While we may reduce some access to our teams, we are still
                working on all sites ensuring our clients get their new homes on
                schedule. Construction has been classified as an essential
                service, so until advised otherwise, we will continue our work.
              </p>
            </Copy>
          </Box>
          <Box width={[1, 6 / 12]} px={2}>
            <Image src={Land2} ratio={1 / 1.5} />
            <br />
            <Overview>Stay inspired, keep dreaming</Overview>
            <br />
            <Copy align="left">
              <p>
                In our ten years we've had the pleasure of creating homes for
                many great clients in some of Australia's most amazing
                locations. With every home we keep maximising subtropical living
                front-of-mind. Get comfy and be inspired by our{' '}
                <Link to="/gallery/">Haus gallery.</Link>
              </p>
            </Copy>
          </Box>
        </Flex>
      </Row>
    </>
  )
}

export default connect(mapDispatchToProps)(Page)

const Desktop = styled.div`
  display: block;
  @media (max-width: 800px) {
    display: none;
  }
`

const Mobile = styled.div`
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
`
