import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { theme } from '../util/style'

export default () => (
  <Flex flexWrap="nowrap">
    <Box width={[1]} px={2}>
      <Sep />
    </Box>
  </Flex>
)

const Sep = styled.hr`
  border: none;
  background: transparent;
  border-bottom: 1px solid ${theme.colors.grey11};
  display: block;
  margin-bottom: 30px;
  margin-top: 0px;
`
